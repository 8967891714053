import { CSSProperties, FunctionComponent, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCookie } from 'react-use'

import { SupportedLocale } from 'data-access/domain/constants'
import { AnnouncementBar, AnnouncementBarItem } from 'data-access/sanity/fragments/components/announcementBar.fragment'
import { Button } from 'ui/components/ui/button'
import { cn } from 'ui/lib/utils'

import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { getAnnouncementBar } from 'src/domain/announcementBar.domain'
import { LayoutProps } from 'src/domain/layoutPropsMapper.domain'
import { getContrastTextColor } from 'src/utils/color.util'
import { I18N_COUNTRY_CODE, I18N_COUNTRY_CODE_RAW } from '../../../middleware'
import RichText from '../../shared/rich-text/rich-text'
import { CountryPicker } from './CountryPicker'
import MisterDropdownMenu from './MisterDropdownMenu'

interface MisterAnnouncementBarProps {
  defaultAnnouncementBar?: AnnouncementBar
  secondaryMenuItems: NonNullable<LayoutProps['navigation']>['secondaryMenuItems']
}

const MisterAnnouncementBar: FunctionComponent<MisterAnnouncementBarProps> = ({ defaultAnnouncementBar, secondaryMenuItems }) => {
  const { locale } = useRouter()
  const { displayMobileMenu, displaySearchUI } = useUI()
  const [geoCountry] = useCookie(I18N_COUNTRY_CODE) // Cloudflare country code, filter on Next.js locales
  const [geoCurrentCountry] = useCookie(I18N_COUNTRY_CODE_RAW) // Cloudflare country code, unfiltered

  const { discountCampaigns } = useContext(GlobalsContext)
  const discountCampaignsAnnouncementBarItems = discountCampaigns?.flatMap((campaign) => campaign?.announcementBar?.announcementBarItems || [])
  const discountCampaignColor = discountCampaigns && discountCampaigns[0]?.productAnnouncementBar?.backgroundColor?.hex

  // Query by country/region, return copy in locale; in case object does not exist for a
  // region not defined in data model (e.g. chAnnouncementBar), rely on fallback/defaultAnnouncementBar
  const { data } = useQuery({
    queryKey: ['announcementBar', geoCurrentCountry, locale, geoCountry],
    queryFn: () =>
      getAnnouncementBar({
        countryCode: geoCurrentCountry || 'nonExistent',
        locale: (geoCountry || locale) as SupportedLocale,
        defaultAnnouncementBar,
      }),
    enabled: !!geoCurrentCountry,
    initialData: defaultAnnouncementBar,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  const announcementBar = data || defaultAnnouncementBar

  const items = [...(discountCampaignsAnnouncementBarItems || []), ...(announcementBar?.announcementBarItems || [])].filter((item) => {
    if (!item) return false
    const bodyText = Array.isArray(item?.body) ? item?.body?.[0]?.children?.[0]?.text : item?.body

    return (bodyText || '').length > 3
  })
  const itemCount = items.length
  const backgroundColor = discountCampaignColor || announcementBar?.backgroundColor
  const contrastedTextColor = backgroundColor ? getContrastTextColor(backgroundColor) : '#ffffff'
  const animationDuration = 6

  const announcementBarText = (text: AnnouncementBarItem['body']) => {
    if (Array.isArray(text)) {
      return <RichText data={text} className='text-body-md text-[var(--contrastedTextColor)]' />
    }

    return text
  }

  const hideAnnouncementBar = displayMobileMenu || displaySearchUI

  return (
    <div
      data-announcement-bar
      style={{ '--backgroundColor': backgroundColor, '--contrastedTextColor': contrastedTextColor } as CSSProperties}
      className={cn(
        'relative flex h-announcement-bar-height px-10 transition-[height,background-color] duration-500 ease-out lg:ease-in-out',
        backgroundColor ? 'bg-[var(--backgroundColor)]' : 'bg-brand-blue',
        contrastedTextColor && `text-[var(--contrastedTextColor)]`,
        hideAnnouncementBar && 'h-0 bg-white',
      )}
    >
      <ul className='group absolute inset-0 flex w-full overflow-hidden text-center'>
        {items.map((item, i) => {
          const segmentPercentage = 100 / items.length

          return (
            <li
              key={`announcement-${i}`}
              style={
                {
                  animationDuration: `${animationDuration * items.length}s`,
                  animationDelay: `${i * animationDuration}s`,
                  animationName: itemCount > 1 ? `ticker-${i}` : 'none',
                  animationIterationCount: 'infinite',
                } as React.CSSProperties
              }
              className={cn(
                'absolute inset-0 flex h-announcement-bar-height w-full items-center justify-center overflow-hidden px-10 text-center lg:text-left xl:text-center',
                itemCount > 1 && 'pointer-events-none opacity-0 will-change-[opacity] md:group-hover:[animation-play-state:paused]',
              )}
            >
              <style>
                {`
                  @keyframes ticker-${i} {
                    0% { opacity: 0; }
                    ${(segmentPercentage * 0.2).toFixed(2)}% { opacity: 1; }
                    ${(segmentPercentage * 1).toFixed(2)}% { opacity: 1; }
                    ${(segmentPercentage * 1.2).toFixed(2)}% { opacity: 0; }
                    100% { opacity: 0; }
                  }
                `}
              </style>
              {item.link ? (
                <MisterSiteLink className='w-full' link={item.link}>
                  {announcementBarText(item.body)}
                </MisterSiteLink>
              ) : (
                announcementBarText(item.body)
              )}
            </li>
          )
        })}
      </ul>
      <nav className='z-10 ml-auto hidden items-center text-[var(--contrastedTextColor)] lg:flex'>
        {secondaryMenuItems &&
          secondaryMenuItems.length &&
          secondaryMenuItems.map((item, i) =>
            item?._type === 'link' ? (
              <div key={i} className='flex items-center px-3'>
                <MisterSiteLink key={i} link={item} className='contents'>
                  <Button variant='link' hover='underline' className='text-body-md text-[var(--contrastedTextColor)]'>
                    {item.linkText}
                  </Button>
                </MisterSiteLink>
              </div>
            ) : (
              item?._type === 'secondaryMenuItem' && <MisterDropdownMenu key={i} item={item} align='start' />
            ),
          )}
        <CountryPicker />
      </nav>
    </div>
  )
}

export default MisterAnnouncementBar
