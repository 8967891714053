import { FunctionComponent } from 'react'
import { Download } from 'lucide-react'

import { Button } from '@ui/components/ui/button'

import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useAccountContext } from 'src/contexts/Account.context'

interface InvoiceButtonProps {
  orderId?: string
  orderNumber?: number
}

const InvoiceButton: FunctionComponent<InvoiceButtonProps> = ({ orderId, orderNumber }) => {
  const {
    storeDomain,
    accountData: { invoice },
  } = useAccountContext()
  const invoicePdfUrl = `https://${storeDomain}/apps/sufio/invoice/download/?id=${orderId}&number=${orderNumber}`

  if (!storeDomain || !orderId || !orderNumber) {
    return null
  }

  return (
    <Button size='sm' variant='outline' asChild className='flex gap-2'>
      <MisterSiteLink link={{ newTab: true, type: 'external', externalLink: invoicePdfUrl }}>
        <Download className='inline size-4' />
        {invoice}
      </MisterSiteLink>
    </Button>
  )
}

export default InvoiceButton
