import { FunctionComponent, useState } from 'react'
import { ChevronDown } from 'lucide-react'

import { Button } from '@ui/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@ui/components/ui/dropdown-menu'

import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { LayoutProps } from 'src/domain/layoutPropsMapper.domain'

type SecondaryMenuItem = NonNullable<LayoutProps['navigation']>['secondaryMenuItems'][number] & { _type: 'secondaryMenuItem' }

const MisterDropdownMenu: FunctionComponent<{ item: SecondaryMenuItem; align: 'start' | 'end' }> = ({ item, align }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className='group flex items-center px-3' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
        <DropdownMenuTrigger asChild className='group focus-within:ring-transparent'>
          <Button variant='link' hover='underline' className='flex items-center gap-2 text-body-md text-current duration-300 data-[state=open]:bg-[length:100%_0.05rem]'>
            {item.secondaryMenuItemTitle}
            <ChevronDown className='size-4 transition-transform duration-300 group-data-[state=open]:rotate-180' />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align={align} sideOffset={0} className='mt-2.5 flex min-w-40 flex-col items-start border-0 px-0 py-3'>
          {item?.secondarySubmenuItems?.length > 0 &&
            item.secondarySubmenuItems.map((secondarySubmenuItem, i) => (
              <DropdownMenuItem key={`dropdown${i}`} className='w-full rounded-none px-3 hover:bg-[unset] focus:bg-[unset] focus:bg-none'>
                <Button asChild variant='link' hover='underline' className='text-body-md'>
                  <MisterSiteLink link={secondarySubmenuItem}>{secondarySubmenuItem.linkText}</MisterSiteLink>
                </Button>
              </DropdownMenuItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default MisterDropdownMenu
