import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'
import { SectionSearchFiltersGridData } from 'data-access/sanity/fragments/sections/sectionSearchFiltersGrid.fragment'
import DynamicBreadcrumb from '@ui/components/ui/dynamic-breadcrumb'
import { FilterMenuToggle } from '@ui/components/ui/filter-menu-toggle'

import { createBreadcrumbItems } from 'src/utils/breadcrumb.util'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useTranslations } from '../../../contexts/Globals.context'
import { useUI } from '../../../contexts/UI.context'
import CollectionSearchFilters from '../../pages/collections/CollectionSearchFilters'

type Props = {
  data: SectionSearchFiltersGridData
}
const SectionSearchFiltersGrid: FunctionComponent<Props> = ({ data }) => {
  const { title, longDescription, filterConfiguration, breadcrumb } = data
  const translate = useTranslations()
  const { locale } = useRouter()
  const { toggleFilters, filtersActive, displayFilters, displayMobileFilters, toggleMobileFilters } = useUI()
  const breadcrumbItems = createBreadcrumbItems(breadcrumb, locale as SupportedLocale)

  return (
    <section className='col-span-full flex w-full flex-col gap-y-6 px-4 lg:gap-y-10 lg:px-8'>
      <header className='flex flex-col gap-y-6'>
        {breadcrumbItems.length > 1 && <DynamicBreadcrumb pages={breadcrumbItems} />}
        <div className='flex flex-row gap-2 lg:gap-8'>
          {filterConfiguration.enableFilters && (
            <div className='order-2 lg:order-1'>
              {/* Desktop */}
              <FilterMenuToggle
                onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_TOGGLE_FILTERS, { filterState: displayFilters ? 'closed' : 'opened' })}
                defaultPressed={displayFilters}
                pressed={displayFilters}
                onPressedChange={toggleFilters}
                filtersActive={filtersActive}
                className='hidden size-fit lg:flex'
              >
                {translate('filterAndSort', 'Filter & sort')}
              </FilterMenuToggle>

              {/* Mobile */}
              <FilterMenuToggle
                onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_TOGGLE_FILTERS, { filterState: displayMobileFilters ? 'closed' : 'opened' })}
                defaultPressed={displayMobileFilters}
                pressed={displayMobileFilters}
                onPressedChange={toggleMobileFilters}
                filtersActive={filtersActive}
                className='size-fit lg:hidden'
              >
                {translate('filterAndSort', 'Filter & sort')}
              </FilterMenuToggle>
            </div>
          )}
          <div className='order-1 flex grow flex-col gap-2 lg:order-2'>
            <h1 className='text-heading-3 font-semibold'>{title}</h1>
            {longDescription && <p className='hidden text-body-md lg:block'>{longDescription}</p>}
          </div>
        </div>

        {longDescription && <p className='block text-body-md lg:hidden'>{longDescription}</p>}
      </header>
      <CollectionSearchFilters data={data} />
    </section>
  )
}

export default SectionSearchFiltersGrid
