import { Dispatch, FunctionComponent, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'

import { MainMenuItem, StyleMenu } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Badge } from '@ui/components/ui/badge'
import { Button } from '@ui/components/ui/button'
import { NavigationMenuContent, NavigationMenuLink } from '@ui/components/ui/navigation-menu'
import { cn } from '@ui/lib/utils'
import { sort } from 'utilities/array/sortArray'
import { convertSlug } from 'utilities/string/url'

import MisterHoverDelay from 'src/components/shared/effects/MisterHoverDelay'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { GlobalsContext, useTranslations } from 'src/contexts/Globals.context'
import { getDiscountCampaignByCollectionId } from 'src/utils/discountCampaign.util'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { DiscountBadge } from '../../announcement/DiscountBadge'
import MegaMenuLevelOne from './MegaMenuLevelOne'
import { MegaMenuLevelThree } from './MegaMenuLevelThree'
import { MegaMenuLevelTwo } from './MegaMenuLevelTwo'

interface DesktopMenuContentProps {
  mainMenuItem: MainMenuItem
  activeStyle: number | null
  setActiveStyle: Dispatch<SetStateAction<number | null>>
  capturedNavigationMenuState: string
}

export const DesktopMenuContent: FunctionComponent<DesktopMenuContentProps> = ({ mainMenuItem, activeStyle, setActiveStyle, capturedNavigationMenuState }) => {
  const { locale, push } = useRouter()
  const translation = useTranslations()
  const { stylesOrder, discountCampaigns } = useContext(GlobalsContext)
  const [localActiveProductCategory, setLocalActiveProductCategory] = useState<number>(0)
  const [hoveredStyle, setHoveredStyle] = useState<StyleMenu | null>(null)
  const [hoveredStyleId, setHoveredStyleId] = useState<string | null>(null)

  const levelOneMenu = <MegaMenuLevelOne key='first' mainMenuCards={mainMenuItem.mainMenuCards} />
  const levelTwoMenu = (i: number) =>
    mainMenuItem.productCategories && mainMenuItem.productCategories[i] ? <MegaMenuLevelTwo key={i} productCategory={mainMenuItem.productCategories[i]} /> : null
  const levelThreeMenu = (style: StyleMenu) => (style ? <MegaMenuLevelThree key={style.slug.current} style={style} /> : null)

  const [NextSection, setNextSection] = useState<ReactElement<any, any> | null>(null)
  const [CurrentSection, setCurrentSection] = useState(levelOneMenu)

  const resetSections = () => {
    if (NextSection) {
      setCurrentSection(NextSection)
      setNextSection(null)
    }
  }

  useEffect(() => {
    setLocalActiveProductCategory(0)
    setCurrentSection(levelOneMenu)
    setNextSection(null)
  }, [capturedNavigationMenuState])

  const handleLevelTwoHover = (i: number, keepCards = false) => {
    setLocalActiveProductCategory(i)
    setActiveStyle(null)
    if ((mainMenuItem.productCategories ?? [])[i] && CurrentSection?.key !== `${i}`) {
      if (CurrentSection.key === levelOneMenu.key && keepCards) {
        setCurrentSection(levelOneMenu)
      } else {
        setNextSection(keepCards ? levelOneMenu : levelTwoMenu(i))
      }
    }
  }

  const handleStyleHover = (style: StyleMenu, uniqueId: string) => {
    setHoveredStyle(style)
    setHoveredStyleId(uniqueId)
    setNextSection(levelThreeMenu(style))
  }

  return (
    <NavigationMenuContent className='group/menu-content'>
      <div className='mx-auto grid max-h-screen min-h-[30.5625rem] max-w-[90rem] grid-cols-12 gap-3 overflow-y-scroll py-8 scrollbar-hide lg:px-8 xl:px-[4.5rem] 2xl:min-h-[32rem]'>
        <div className='col-span-6 grid grid-cols-6 gap-2 ease-in-out animate-in fade-in-0 slide-in-from-bottom-5 fill-mode-both [animation-duration:500ms] group-data-[motion]/menu-content:animate-none group-data-[state=closed]/menu:animate-out group-data-[state=closed]/menu:fade-out-5 group-data-[state=closed]/menu:slide-out-to-top-5 group-data-[state=open]/menu:[animation-delay:300ms]'>
          <div className='col-span-1 mt-10 flex flex-col gap-4' />
          {(mainMenuItem.productCategories?.length ?? 0) > 1 ? (
            <ul className='col-span-2 flex flex-col gap-4'>
              <h3 className='text-body-sm uppercase text-brand-grey-dark'>{translation('shopByType', 'Shop by type')}</h3>
              {mainMenuItem.productCategories?.map((productCategory, i) => (
                <li key={i} className='flex gap-2'>
                  <MisterHoverDelay delay={50}>
                    <Button
                      onMouseEnter={() => handleLevelTwoHover(i, true)}
                      variant='link'
                      hover='underline'
                      data-testid='desktop-menu-category-link'
                      className={cn('inline whitespace-normal', localActiveProductCategory === i && 'bg-[length:100%_0.05rem]')}
                      asChild
                    >
                      <NavigationMenuLink
                        href={makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory?.productCategory?.slug, _type: 'collection' } }, locale)}
                        onClick={(e) => {
                          e.preventDefault()
                          const href = makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory.productCategory.slug, _type: 'collection' } }, locale)

                          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                            clickDestination: href,
                            navigationLevel: 2,
                            variant: NAVIGATION_LINK_VARIANTS.LINK,
                          })
                          push(href)
                        }}
                        className='relative'
                      >
                        {productCategory.productCategoryMenuTitle}
                      </NavigationMenuLink>
                    </Button>
                  </MisterHoverDelay>
                  <Button variant='ghost' onClick={() => handleLevelTwoHover(i, true)} className='flex w-0 overflow-hidden !bg-none p-0 focus:w-4'>
                    <ChevronRight className='block size-4' />
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          {localActiveProductCategory !== null && mainMenuItem?.productCategories?.[localActiveProductCategory] && (
            <ul className='col-span-3 flex flex-col gap-4'>
              {mainMenuItem.productCategories?.length > 1 && <h3 className='text-body-sm uppercase text-brand-grey-dark'>{translation('shopByStyle', 'Shop by style')}</h3>}
              {(mainMenuItem?.productCategories?.[localActiveProductCategory]?.styles?.length ?? 0) > 1 && (
                <li>
                  <MisterHoverDelay delay={50}>
                    <NavigationMenuLink asChild>
                      <Button
                        onMouseEnter={() => handleLevelTwoHover(localActiveProductCategory)}
                        variant='link'
                        hover='underline'
                        data-testid='desktop-menu-style-link'
                        className={cn('inline whitespace-normal hover:bg-[length:100%_0.05rem]')}
                        asChild
                      >
                        <MisterSiteLink
                          onClick={() => {
                            const href = makeHref(
                              {
                                _type: 'link',
                                type: 'internal',
                                page: { slug: mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory.slug, _type: 'collection' },
                              },
                              locale,
                            )

                            trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                              clickDestination: href,
                              navigationLevel: 3,
                              variant: NAVIGATION_LINK_VARIANTS.LINK,
                            })
                          }}
                          link={convertSlug(mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory?.slug, 'collection')}
                          className='relative'
                        >
                          {mainMenuItem?.productCategories?.[localActiveProductCategory]?.productCategory?.title}
                        </MisterSiteLink>
                      </Button>
                    </NavigationMenuLink>
                  </MisterHoverDelay>
                </li>
              )}
              {(stylesOrder.applyOrderTo?.header
                ? sort(mainMenuItem.productCategories[localActiveProductCategory]?.styles ?? [], stylesOrder.styles || [])
                : mainMenuItem.productCategories[localActiveProductCategory]?.styles
              )?.map((style, i) => {
                const discountCampaign = getDiscountCampaignByCollectionId(discountCampaigns, style._id)

                return (
                  <li className='flex items-start gap-3' key={i}>
                    <MisterHoverDelay delay={50}>
                      <NavigationMenuLink asChild>
                        <Button
                          onMouseEnter={() => handleStyleHover(style, `control-style-${localActiveProductCategory}-${i}`)}
                          variant='link'
                          hover='underline'
                          data-testid='desktop-menu-style-link'
                          className={cn(
                            'inline whitespace-normal',
                            hoveredStyle?.slug?.current === style?.slug?.current &&
                              hoveredStyleId === `control-style-${localActiveProductCategory}-${i}` &&
                              'bg-[length:100%_0.05rem]',
                          )}
                          asChild
                        >
                          <MisterSiteLink
                            onClick={() => {
                              const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } }, locale)
                              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                                clickDestination: href,
                                navigationLevel: 3,
                                variant: NAVIGATION_LINK_VARIANTS.LINK,
                              })
                            }}
                            link={convertSlug(style.slug, 'collection')}
                            className='relative'
                          >
                            {style.title}
                          </MisterSiteLink>
                        </Button>
                      </NavigationMenuLink>
                    </MisterHoverDelay>
                    {style.new ? (
                      <Badge size='sm' className='mt-1 h-5 bg-brand-blue'>
                        {translation('new', 'New')}
                      </Badge>
                    ) : discountCampaign && discountCampaign?.showInNavigation ? (
                      <DiscountBadge discount={discountCampaign?.discount} backgroundColor={discountCampaign?.discountPillBackgroundColor} />
                    ) : null}
                  </li>
                )
              })}
            </ul>
          )}
        </div>

        <div
          data-level={localActiveProductCategory}
          className='relative col-span-6 gap-3 ease-in-out animate-in fade-in-0 slide-in-from-top-5 fill-mode-both [animation-duration:500ms] group-data-[motion]/menu-content:animate-none group-data-[state=closed]/menu:animate-out group-data-[state=closed]/menu:fade-out-5 group-data-[state=closed]/menu:slide-out-to-bottom-5 group-data-[state=open]/menu:[animation-delay:300ms]'
        >
          <div
            data-motion={NextSection ? 'exit' : null}
            className='absolute inset-0 h-full gap-10 data-[motion=enter]:duration-300 data-[motion=exit]:duration-300 data-[motion=enter]:animate-in data-[motion=exit]:animate-out data-[motion=enter]:fade-in data-[motion=exit]:fade-out data-[motion=enter]:fill-mode-forwards data-[motion=exit]:fill-mode-forwards'
            onAnimationEnd={resetSections}
          >
            {CurrentSection}
          </div>
          {NextSection && (
            <div
              data-motion='enter'
              className='absolute inset-0 h-full gap-10 data-[motion=enter]:duration-300 data-[motion=exit]:duration-300 data-[motion=enter]:animate-in data-[motion=exit]:animate-out data-[motion=enter]:fade-in data-[motion=exit]:fade-out data-[motion=enter]:fill-mode-forwards data-[motion=exit]:fill-mode-forwards'
              onAnimationEnd={resetSections}
            >
              {NextSection}
            </div>
          )}
        </div>
      </div>
    </NavigationMenuContent>
  )
}
