import { FormEvent, FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'
import { LoaderCircle } from 'lucide-react'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'
import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'

import { ContextCustomer } from '@/types/contextCustomer'

import MisterDropdown from 'src/components/shared/input/MisterDropdown'
import { useAccountContext } from 'src/contexts/Account.context'
import { getAvailableCountries } from 'src/domain/availableCountries.domain'
import useCustomer from 'src/hooks/customer/useCustomer'
import { useTranslations } from '../../../../../contexts/Globals.context'
import useCustomerAddress from '../../../../../hooks/customer/useCustomerAddress'
import MisterInput from '../../../../shared/input/MisterInput'

interface AddressFormProps {
  inputFields: { [key: string]: any }
  updateFields: (e: any) => void
  customer: ContextCustomer
}

const AddressForm: FunctionComponent<AddressFormProps> = ({ inputFields, updateFields, customer }) => {
  const { customer: ironCustomer } = useCustomer()
  const { locale } = useRouter()
  const translate = useTranslations()
  const {
    accountData: { city, country, postalCode, streetName, userInfoChangeSuccessMesssage, saveAddress },
  } = useAccountContext()
  const {
    updateAddress,
    state: { response, loading, error },
  } = useCustomerAddress()

  const { data: countries } = useQuery({
    queryKey: ['availableCountries', locale],
    queryFn: () => getAvailableCountries(locale as SupportedLocale),
    enabled: !!locale,
  })

  const primaryOptions = [
    ...(countries?.country ? [countries.country] : []),
    ...(countries?.availableCountries?.filter((country) => country.isoCode === inputFields?.isoCode || country.isoCode === locale?.toUpperCase()) ?? []),
  ].filter((country, index, self) => index === self.findIndex((c) => c.isoCode === country.isoCode))

  if (!ironCustomer.isLoggedIn) {
    return null
  }

  const handleEditAddress = (e: FormEvent) => {
    e.preventDefault()

    updateAddress({
      customerAccessToken: ironCustomer?.customerAccessToken,
      id: customer?.defaultAddress?.id,
      address: {
        firstName: inputFields?.firstNameAddress,
        lastName: inputFields?.lastNameAddress,
        address1: inputFields?.streetName,
        city: inputFields?.city,
        country: inputFields?.isoCode,
        zip: inputFields?.postalCode,
      },
    })
  }

  return (
    <form onSubmit={(e) => handleEditAddress(e)} className='mb-8 grid w-full max-w-2xl gap-x-4 lg:w-[57%] lg:grid-cols-4'>
      <MisterInput
        wrapperClassName='col-span-4 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('firstName')}
        name='firstNameAddress'
        id='firstNameAddress'
        placeholder={translate('firstName')}
        onChangeHandler={updateFields}
        value={inputFields?.firstNameAddress}
        autoComplete='given-name'
      />
      <MisterInput
        wrapperClassName='col-span-4 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('lastName')}
        name='lastNameAddress'
        id='lastNameAddress'
        placeholder={translate('lastName')}
        onChangeHandler={updateFields}
        value={inputFields?.lastNameAddress}
        autoComplete='family-name'
      />
      <MisterInput
        wrapperClassName='col-span-4'
        inputClassName='!bg-brand-grey'
        type='text'
        label={streetName}
        name='streetName'
        id='streetName'
        placeholder={streetName}
        onChangeHandler={updateFields}
        value={inputFields?.streetName}
        required={true}
        autoComplete='address-line1'
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={postalCode}
        name='postalCode'
        id='postalCode'
        placeholder={postalCode}
        onChangeHandler={updateFields}
        value={inputFields?.postalCode}
        required={true}
        autoComplete='postal-code'
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={city}
        name='city'
        id='city'
        placeholder={city}
        onChangeHandler={updateFields}
        value={inputFields?.city}
        required={true}
        autoComplete='address-level2'
      />

      <MisterDropdown
        wrapperClassName='col-span-4'
        value={inputFields?.isoCode}
        name='country'
        id='country'
        label={country}
        onChangeHandler={(option) => {
          updateFields({ ['isoCode']: option })
          updateFields({ ['country']: countries?.availableCountries.find((country) => country.isoCode === option)?.name })
        }}
        options={countries?.availableCountries
          .map((country) => {
            return { label: country.name, value: country.isoCode }
          })
          .sort((a, b) => a.label.localeCompare(b.label))}
        primaryOptions={primaryOptions.map((country) => {
          return { label: country.name, value: country.isoCode }
        })}
        inForm
      />

      <div className='col-span-2'>
        <div className={cn(response ? 'mt-4 block text-body-sm lg:mt-0' : 'hidden')}>{userInfoChangeSuccessMesssage}</div>
      </div>
      <div className='col-span-4 flex justify-end'>
        <Button
          size='md'
          type='submit'
          className='flex gap-2'
          disabled={
            customer?.defaultAddress?.firstName === inputFields?.firstNameAddress &&
            customer?.defaultAddress?.lastName === inputFields?.lastNameAddress &&
            customer?.defaultAddress?.address1 === inputFields?.streetName &&
            customer?.defaultAddress?.city === inputFields?.city &&
            customer?.defaultAddress?.country === inputFields?.country &&
            customer?.defaultAddress?.zip === inputFields?.postalCode
          }
        >
          {loading && <LoaderCircle className='size-4 animate-spin' />}
          {saveAddress}
        </Button>
      </div>
      {error && <div className='mt-4 block text-brand-warning lg:mt-0'>{error}</div>}
    </form>
  )
}

export default AddressForm
