import { FunctionComponent } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Slug } from 'data-access/sanity/types'
import { ShopifyOrderLineItem } from 'data-access/shopify/types'
import { formatPrice } from 'utilities/string/format/price'
import { convertSlug } from 'utilities/string/url'

import MisterIcon from 'src/components/shared/MisterIcon'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { AccountPageProps } from 'src/domain/accountPageProps.domain'
import { imageQuality } from '../../../utils/image.util'

interface MisterOrderLineItemProps {
  lineItem: ShopifyOrderLineItem
  index: number
  accountData: AccountPageProps['accountData']
}

const MisterOrderLineItem: FunctionComponent<MisterOrderLineItemProps> = ({ lineItem, index, accountData }) => {
  const { locale } = useRouter()
  const { variant, title, slug, quantity } = lineItem || {}

  const setLink = (slug: Slug | null, children: any) => {
    return slug ? (
      <MisterSiteLink className='hover:underline focus:outline-none' link={convertSlug(slug, 'product', true)}>
        {children}
      </MisterSiteLink>
    ) : (
      <div className='cursor-help' title={accountData?.productNoLongerAvailable || 'Product no longer available'}>
        {children}
      </div>
    )
  }

  return (
    <li key={`${variant?.image?.url}${index}`} className='relative flex border-b p-4 md:p-8 odd:md:border-r'>
      {variant ? (
        setLink(
          slug,
          <div className='relative mr-4 h-[121px] w-[80px] sm:mr-8 sm:h-[193px] sm:w-[128px]'>
            {variant?.image?.url && (
              <Image
                loading='lazy'
                sizes='(max-width: 480px) 480px, (max-width: 768px) 768px, (max-width: 1281px) 1281px, 1920px'
                src={variant?.image?.url}
                fill
                alt={title}
                quality={imageQuality}
              />
            )}
          </div>,
        )
      ) : (
        <div className='relative mr-4 h-[121px] w-[80px] bg-brand-grey sm:mr-8 sm:h-[193px] sm:w-[128px]' />
      )}
      {variant ? (
        <div>
          <div>
            {setLink(
              slug,
              <div className='mb-2 flex items-center gap-1 text-body-sm-bold'>
                <p>{title}</p>
                {slug && <MisterIcon type='new-tab' className='size-3' />}
              </div>,
            )}
          </div>
          <div className='flex text-body-md'>
            <p className='mr-2'>{variant?.title}</p>
            <span className='mr-2 -translate-y-1 text-body-md-bold'>.</span>
            <p className='mr-1'>{quantity}</p>
            <span className='mr-1'>x</span>
            {variant?.price?.amount ? <p>{formatPrice(variant?.price?.amount, locale)} </p> : null}
          </div>
        </div>
      ) : (
        <div>
          <p className='mb-2 h-4 w-40 bg-brand-grey text-body-sm-bold'></p>
          <div className='flex text-body-md'>
            <p className='mr-2 h-4 w-24 bg-brand-grey'></p>
            <p className='mr-1 h-4 w-8 bg-brand-grey'></p>
          </div>
        </div>
      )}
    </li>
  )
}

export default MisterOrderLineItem
