import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import { MenuCard as MenuCardType } from 'data-access/sanity/fragments/components/menuCard.fragment'
import { NavigationMenuLink } from '@ui/components/ui/navigation-menu'

import { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MenuCard from '../MenuCard'

interface MegaMenuLevelOneProps {
  mainMenuCards: MenuCardType[]
}
const MegaMenuLevelOne: FunctionComponent<MegaMenuLevelOneProps> = ({ mainMenuCards }) => {
  const { locale } = useRouter()

  return (
    <div className='grid grid-cols-2 gap-3'>
      {mainMenuCards
        ?.filter(({ pageLink }) => '_type' in pageLink)
        ?.map(({ image, pageLink }, i) => (
          <NavigationMenuLink key={i} asChild>
            <MenuCard
              onClick={() =>
                trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                  clickDestination: makeHref(pageLink, locale),
                  navigationLevel: 4,
                  variant: NAVIGATION_LINK_VARIANTS.CARD,
                })
              }
              image={image}
              link={pageLink}
              text={pageLink.linkText}
              className='aspect-[35:48] max-h-[27rem]'
            />
          </NavigationMenuLink>
        ))}
    </div>
  )
}

export default MegaMenuLevelOne
