import { createContext, useContext } from 'react'

import { ContextCustomer } from '@/types/contextCustomer'

import { AccountPageProps } from 'src/domain/accountPageProps.domain'

export const AccountContext = createContext<{ customer: ContextCustomer | null; accountData: AccountPageProps['accountData'] | null; storeDomain?: string | null }>({
  customer: null,
  accountData: null,
  storeDomain: null,
})

export const useAccountContext = () => {
  const { customer, accountData, storeDomain } = useContext(AccountContext)
  if (!customer || !accountData || !storeDomain) throw new Error('useAccountContext must be used within AccountContextProvider')
  return { customer, accountData, storeDomain }
}
