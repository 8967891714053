import { createContext, useContext, useMemo } from 'react'

import { DefaultPageProps } from 'src/domain/defaultPageProps.domain'

export const defaultGlobals = {
  global: {},
  collection: {},
  product: {},
  colorOrder: [],
  localizedSettings: { showCurrencySymbol: true },
  discountCampaigns: [],
  searchSettingsTerms: {},
  searchSettings: {
    discoverCollectionsItems: [],
    searchHelpLinks: [],
    popularSearchTerms: [],
  },
  stylesOrder: {},
} as NonNullable<DefaultPageProps['globals']>

export const GlobalsContext = createContext(defaultGlobals)

// TODO: Change the return type to string once 'policies' moved from global product terms
export type Translate = (name: string, fallback?: string | boolean) => any

export const useTranslations = (): Translate => {
  const globalTerms = useContext(GlobalsContext)

  if (globalTerms === undefined) {
    throw new Error('useTranslations must be used within a GlobalsContextProvider.')
  }

  const allTerms = useMemo(
    () => ({ ...globalTerms?.global, ...globalTerms?.product, ...globalTerms?.collection, ...globalTerms?.searchSettings, ...globalTerms?.searchSettingsTerms }),
    [globalTerms],
  )

  return (name: string, fallback: string | boolean = false) => {
    // @ts-ignore
    const translation = allTerms[name]
    const fallbackTranslation = fallback && typeof fallback === 'string' ? fallback : undefined

    return translation || fallbackTranslation
  }
}
