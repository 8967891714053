import { FunctionComponent, useReducer } from 'react'

import AddressForm from 'src/components/pages/account/personal-info/address-form/address-form'
import PasswordForm from 'src/components/pages/account/personal-info/password-form/password-form'
import UserInfoForm from 'src/components/pages/account/personal-info/user-info-form/user-info-form'
import MisterContainer from 'src/components/shared/MisterContainer'
import { useAccountContext } from 'src/contexts/Account.context'

const SectionPersonalInfo: FunctionComponent = () => {
  const { customer, accountData } = useAccountContext()
  const { firstName, lastName, email, defaultAddress } = customer || {}
  const [inputFields, updateFields] = useReducer(
    (data: any, partialData: any) => ({
      ...data,
      ...partialData,
    }),
    {
      firstName: firstName,
      lastName: lastName,
      email: email,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      firstNameAddress: defaultAddress?.firstName,
      lastNameAddress: defaultAddress?.lastName,
      streetName: defaultAddress?.address1,
      houseNumber: '',
      addition: '',
      postalCode: defaultAddress?.zip,
      city: defaultAddress?.city,
      country: defaultAddress?.country,
      isoCode: defaultAddress?.countryCodeV2,
    },
  )

  const { personalInfo, userInfo, password, address } = accountData

  return (
    <MisterContainer dataLabel='section-personal-info' type='fluid'>
      <div className='mb-24 mt-8 flex flex-col px-4 lg:px-16'>
        <h1 className='mb-8 text-heading-5 lg:text-heading-3'>{personalInfo}</h1>

        <p className='mb-6 text-heading-5 lg:text-heading-5'>{userInfo}</p>
        <UserInfoForm inputFields={inputFields} updateFields={updateFields} />

        <p className='mb-6 text-heading-5 lg:text-heading-5'>{password}</p>
        <PasswordForm inputFields={inputFields} updateFields={updateFields} />

        <p className='mb-6 text-heading-5 lg:text-heading-5'>{address}</p>
        <AddressForm inputFields={inputFields} updateFields={updateFields} customer={customer} />
      </div>
    </MisterContainer>
  )
}

export default SectionPersonalInfo
