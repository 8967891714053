import { FunctionComponent, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ChevronDown } from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'
import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Button } from '@ui/components/ui/button'
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from '@ui/components/ui/navigation-menu'
import { cn } from '@ui/lib/utils'
import { getBaseUrl } from 'utilities/string/url'

import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import AlgoliaContextProvider from 'src/contexts/AlgoliaSearch.context'
import { MobileMenuContextProvider } from 'src/contexts/MobileMenu.context'
import { useUI } from 'src/contexts/UI.context'
import { DefaultPageProps } from 'src/domain/defaultPageProps.domain'
import { getFullNavigation } from 'src/domain/navigation.domain'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { AccountButton } from './AccountButton'
import { CartButton } from './CartButton'
import { DesktopMenuContent } from './DesktopMenu/DesktopMenuContent'
import LogoSvg from './LogoSvg'
import { MobileMenu } from './MobileMenu/MobileMenu'
import { MobileMenuButton } from './MobileMenu/MobileMenuButton'
import { MobileMenuCloseButton } from './MobileMenu/MobileMenuCloseButton'
import { SearchButton } from './SearchButton'
import { SearchOverlay } from './SearchOverlay/SearchOverlay'
import { WishlistButton } from './WishlistButton'

export const getSiteUrl = (path = '/'): Readonly<string> => `${getBaseUrl()}${path}`

interface HeaderProps {
  data: DefaultPageProps['navigation']
  preview?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({ data, preview }) => {
  if (!data) {
    return
  }
  const { locale, push } = useRouter()
  const [activeStyle, setActiveStyle] = useState<number | null>(null)
  const [capturedNavigationMenuState, setCapturedNavigationMenuState] = useState<string>('')
  const { mainMenuItems, wishlistSlug } = data || {}
  const { displaySearchUI } = useUI()

  const {
    data: navigationData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['navigation', locale, data, preview],
    queryFn: () => getFullNavigation(data, locale as SupportedLocale, preview),
  })

  // Conditional checks for menu when in preview mode or not
  const hasMenu = preview ? !!navigationData : !error && !isLoading && !!navigationData

  const handleMegaMenuChange = (value: string) => {
    setCapturedNavigationMenuState(value)
    if (value === '') {
      setTimeout(() => {
        setActiveStyle(null)
      }, 400)
    } else {
      setActiveStyle(null)
    }
  }

  return (
    <header data-header className={cn('overflow-hidden bg-white', displaySearchUI ? 'lg:overflow-hidden' : 'lg:overflow-visible')}>
      <div className='grid min-h-14 grid-cols-[1fr,auto,1fr] items-center justify-center px-2 py-1 lg:border-b lg:px-10 lg:py-2'>
        <MobileMenuButton />
        <Link
          href='/'
          className='group-hover:text-brand-blue-dark col-start-2 flex items-center justify-center'
          rel='home'
          title='MR MARVIS'
          itemScope
          itemType='https://schema.org/Organization'
        >
          <LogoSvg className='h-[1.063rem] lg:h-6' />
          <meta itemProp='name' content='MR MARVIS' />
          <meta itemProp='url' content={getSiteUrl()} />
          <meta itemProp='logo' content={getSiteUrl('/logo.svg')} />
        </Link>
        <div className='relative flex gap-1 justify-self-end'>
          <SearchButton />
          <AccountButton />
          <WishlistButton wishlistSlug={wishlistSlug} />
          <CartButton />
          <MobileMenuCloseButton />
        </div>
      </div>
      {navigationData && (
        <MobileMenuContextProvider data={navigationData}>
          <AlgoliaContextProvider>
            <MobileMenu />
            <SearchOverlay />
          </AlgoliaContextProvider>
        </MobileMenuContextProvider>
      )}

      <NavigationMenu
        onValueChange={(value) => handleMegaMenuChange(value)}
        className={cn('hidden h-12 w-screen border-b lg:fine-pointer:flex', displaySearchUI && 'lg:h-0 lg:opacity-0 lg:transition-height lg:duration-300')}
      >
        <NavigationMenuList>
          {(navigationData ? navigationData.mainMenuItems : mainMenuItems)?.map((mainMenuItem, i) =>
            mainMenuItem?._type === 'link' ? (
              <NavigationMenuItem asChild key={i}>
                <MisterSiteLink
                  onClick={() =>
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: makeHref(mainMenuItem, locale),
                      navigationLevel: 1,
                      variant: NAVIGATION_LINK_VARIANTS.LINK,
                    })
                  }
                  link={mainMenuItem}
                >
                  <Button className='inline-flex w-max items-center justify-center rounded-none border-none bg-background bg-gradient-to-r from-current to-current bg-[length:0%_0.125rem] bg-[center_bottom_0rem] bg-no-repeat px-4 py-4 text-btn-md font-medium text-primary underline-offset-4 transition-[background-size,color] duration-150 ease-in-out hover:bg-[length:100%_0.125rem] focus:bg-[length:100%_0.125rem] focus:outline-none disabled:pointer-events-none disabled:opacity-50'>
                    {mainMenuItem.linkText}
                  </Button>
                </MisterSiteLink>
              </NavigationMenuItem>
            ) : (
              mainMenuItem?._type === 'mainMenuItem' && (
                <NavigationMenuItem
                  key={i}
                  className='flex'
                  onPointerEnter={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.OPEN_NAVIGATION, { clickDestination: makeHref(mainMenuItem.mainMenuItemLink, locale) })}
                >
                  {/* Hidden chevron to control the menu for keyboard users */}
                  <NavigationMenuTrigger title={mainMenuItem.mainMenuItemLink.linkText} className='flex w-0 overflow-hidden !bg-none p-0 focus:w-4'>
                    <ChevronDown className='block size-4' />
                  </NavigationMenuTrigger>
                  <NavigationMenuTrigger asChild data-testid='desktop-menu-link'>
                    <NavigationMenuLink
                      href={makeHref(mainMenuItem.mainMenuItemLink, locale)}
                      onClick={() =>
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                          clickDestination: makeHref(mainMenuItem.mainMenuItemLink, locale),
                          navigationLevel: 1,
                          variant: NAVIGATION_LINK_VARIANTS.LINK,
                        })
                      }
                      onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          push(makeHref(mainMenuItem.mainMenuItemLink, locale))
                          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                            clickDestination: makeHref(mainMenuItem.mainMenuItemLink, locale),
                            navigationLevel: 1,
                            variant: NAVIGATION_LINK_VARIANTS.LINK,
                          })
                        }
                      }}
                    >
                      {mainMenuItem.mainMenuItemLink.linkText}
                    </NavigationMenuLink>
                  </NavigationMenuTrigger>

                  {hasMenu && (
                    <DesktopMenuContent
                      capturedNavigationMenuState={capturedNavigationMenuState}
                      mainMenuItem={mainMenuItem as MainMenuItem}
                      activeStyle={activeStyle}
                      setActiveStyle={setActiveStyle}
                    />
                  )}
                </NavigationMenuItem>
              )
            ),
          )}
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  )
}

export default Header
