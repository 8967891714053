import { FunctionComponent, ReactNode } from 'react'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'

import { Button } from '@ui/components/ui/button'

import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { countiesAndRegions } from 'src/utils/i18n.util'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MenuCard from '../MenuCard'
import CategoryMenu from './CategoryMenu'
import { CountryPicker } from './CountryPicker'
import SecondaryMenu from './SecondaryMenu'
import StylesMenu from './StylesMenu'

export const Flag: FunctionComponent<{ code?: string }> = ({ code }): ReactNode => {
  const correctedCode = code === 'en' ? 'eu' : code
  const country = correctedCode ? countiesAndRegions.find((c) => c.isoAlpha2 === correctedCode.toUpperCase()) : false

  return (
    <span className='flex w-[15px] items-center justify-center empty:hidden' role='img' aria-label={correctedCode} aria-hidden={true}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {country && <img src={`data:image/png;base64,${country?.flag}`} alt={correctedCode || ''} width={30} height={20} />}
    </span>
  )
}

const TopLevelMobileMenu: FunctionComponent = () => {
  const { handleMobileMenuClose, handleMenuChange, setCurrentMainMenuItem, setCurrentCategory, setCurrentSecondaryMenuItem, data } = useMobileMenu()
  const translate = useTranslations()
  const { locale } = useRouter()

  const { mainMenuItems, secondaryMenuItems } = data || {}

  if (!mainMenuItems) {
    return null
  }

  return (
    <div className='flex h-full flex-col gap-7 px-4'>
      <div className='flex h-full flex-col gap-10 px-0'>
        <ul className='flex flex-col'>
          {mainMenuItems.map((mainMenuItem, i) =>
            mainMenuItem?._type === 'link' ? (
              <li key={i}>
                <Button
                  onClick={() => {
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: makeHref(mainMenuItem, locale),
                      navigationLevel: 1,
                      variant: NAVIGATION_LINK_VARIANTS.LINK,
                    })
                    handleMobileMenuClose()
                  }}
                  variant='ghost'
                  className='flex px-2 py-3 text-btn-xl'
                >
                  <MisterSiteLink link={mainMenuItem}>{mainMenuItem.linkText}</MisterSiteLink>
                </Button>
              </li>
            ) : (
              mainMenuItem?._type === 'mainMenuItem' && (
                <li key={i}>
                  <Button
                    variant='ghost'
                    className='flex items-center gap-1 px-2 py-3 text-btn-xl'
                    data-testid='mobile-menu-link'
                    onClick={() => {
                      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                        clickDestination: makeHref(mainMenuItem.mainMenuItemLink, locale),
                        navigationLevel: 1,
                        variant: NAVIGATION_LINK_VARIANTS.TRIGGER,
                      })
                      if (mainMenuItem?.productCategories?.length === 1) {
                        handleMenuChange({ type: 'styles', component: <StylesMenu /> }, 'forward')
                        setCurrentMainMenuItem(i)
                        setCurrentCategory(0)
                      } else {
                        handleMenuChange({ type: 'categories', component: <CategoryMenu /> }, 'forward')
                        setCurrentMainMenuItem(i)
                      }
                    }}
                  >
                    {mainMenuItem.mainMenuItemLink.linkText}
                    <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
                  </Button>
                </li>
              )
            ),
          )}
        </ul>
        <ul className='mt-auto flex flex-col text-btn-lg'>
          {(secondaryMenuItems ?? []).map((secondaryMenuItem, i) => {
            return secondaryMenuItem?._type === 'link' ? (
              <li key={i}>
                <Button
                  onClick={() => {
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: makeHref(secondaryMenuItem, locale),
                      navigationLevel: 1,
                      variant: NAVIGATION_LINK_VARIANTS.LINK,
                    })

                    handleMobileMenuClose()
                  }}
                  variant='ghost'
                  className='flex items-center gap-1 px-2 py-3'
                >
                  <MisterSiteLink link={secondaryMenuItem}>{secondaryMenuItem.linkText}</MisterSiteLink>
                </Button>
              </li>
            ) : (
              secondaryMenuItem?._type === 'secondaryMenuItem' && (
                <li key={i}>
                  <Button
                    variant='ghost'
                    className='flex items-center gap-1 px-2 py-3'
                    onClick={() => {
                      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                        clickDestination: secondaryMenuItem._type,
                        navigationLevel: 1,
                        variant: NAVIGATION_LINK_VARIANTS.TRIGGER,
                      })
                      handleMenuChange({ type: 'secondary', component: <SecondaryMenu /> }, 'forward'), setCurrentSecondaryMenuItem(i)
                    }}
                  >
                    {secondaryMenuItem.secondaryMenuItemTitle}
                    <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
                  </Button>
                </li>
              )
            )
          })}
          <li>
            <Button variant='ghost' className='flex items-center gap-1 px-2 py-3'>
              <MisterSiteLink
                onClick={() => {
                  trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                    clickDestination: '/account',
                    navigationLevel: 1,
                    variant: NAVIGATION_LINK_VARIANTS.LINK,
                  })

                  handleMobileMenuClose()
                }}
                e2eSelector='account-link'
                link={{ type: 'internal', page: { slug: { current: 'account' } } }}
                title={translate('account', 'Account')}
              >
                {translate('account', 'Account')}
              </MisterSiteLink>
            </Button>
          </li>
          <li>
            <Button
              variant='ghost'
              className='flex items-center gap-1 px-2 py-3'
              onClick={() => {
                trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                  clickDestination: 'countryPicker',
                  navigationLevel: 1,
                  variant: NAVIGATION_LINK_VARIANTS.TRIGGER,
                })

                handleMenuChange({ type: 'countryPicker', component: <CountryPicker /> }, 'forward')
              }}
            >
              <Flag code={locale} />
              {translate(`${locale}CountryPicker`)}
              <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
            </Button>
          </li>
        </ul>
      </div>
      <div className='mt-auto flex gap-2 pb-8'>
        {data?.topMenuCards?.map(({ image, pageLink }, i) => (
          <MenuCard
            key={i}
            image={image}
            link={pageLink}
            text={pageLink.linkText}
            onClick={() => {
              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                clickDestination: makeHref(pageLink, locale),
                navigationLevel: 1,
                variant: NAVIGATION_LINK_VARIANTS.CARD,
              })

              handleMobileMenuClose()
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default TopLevelMobileMenu
