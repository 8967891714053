import { FunctionComponent } from 'react'
import { ChevronLeft } from 'lucide-react'

import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@ui/components/ui/breadcrumb'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import CategoryMenu from './CategoryMenu'
import TopLevelMobileMenu from './TopLevelMobileMenu'
import TransitionContainer from './TransitionContainer'

const MobileMenuBreadcrumbs: FunctionComponent = () => {
  const { data, currentMenu, nextMenu, currentMainMenuItem, handleMenuChange, currentCategory } = useMobileMenu()

  const currentMenuType = currentMenu?.type
  const nextMenuType = nextMenu?.type

  const currentMainMenuItemData = data.mainMenuItems?.[currentMainMenuItem || 0] as MainMenuItem
  const onlyOneProductCategory = currentMainMenuItemData?.productCategories?.length === 1

  return (
    <div className='h-8'>
      <TransitionContainer
        motion={
          nextMenuType === 'top'
            ? 'to-end'
            : nextMenuType === 'categories' || nextMenuType === 'countryPicker' || nextMenuType === 'secondary' || (nextMenuType === 'styles' && onlyOneProductCategory)
              ? 'from-end'
              : currentMenuType === 'top'
                ? 'to-start'
                : 'from-start'
        }
      >
        <div className='flex items-center gap-4 px-4'>
          <Button
            size='icon'
            variant='default'
            className='size-8 rounded-full'
            onClick={() => {
              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_BACK)
              handleMenuChange(
                currentMenuType === 'styles' && !onlyOneProductCategory ? { type: 'categories', component: <CategoryMenu /> } : { type: 'top', component: <TopLevelMobileMenu /> },
                'backward',
              )
            }}
          >
            <ChevronLeft className='mr-0.5 size-5' />
          </Button>

          <Breadcrumb>
            <BreadcrumbList className='w-full flex-nowrap text-btn-lg'>
              <BreadcrumbItem
                className='cursor-pointer'
                onClick={() => {
                  trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_BREADCRUMBS)
                  handleMenuChange({ type: 'top', component: <TopLevelMobileMenu /> }, 'backward')
                }}
              >
                Shop
              </BreadcrumbItem>

              {!onlyOneProductCategory && (
                <div
                  className={cn(
                    'flex items-center gap-1 opacity-0 transition-opacity',
                    ((currentMenuType === 'categories' && nextMenuType !== 'top') || nextMenuType === 'categories' || currentMenuType === 'styles') && 'opacity-1',
                  )}
                >
                  <BreadcrumbSeparator />
                  <BreadcrumbItem
                    className='cursor-pointer'
                    onClick={() => {
                      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_BREADCRUMBS)
                      handleMenuChange({ type: 'categories', component: <CategoryMenu /> }, 'backward')
                    }}
                  >
                    {currentMainMenuItemData.mainMenuItemLink.linkText}
                  </BreadcrumbItem>
                </div>
              )}

              <div
                className={cn(
                  'flex items-center gap-1 opacity-0 transition-opacity',
                  ((currentMenuType === 'styles' && nextMenuType !== 'categories') || nextMenuType === 'styles') && 'opacity-1',
                )}
              >
                <BreadcrumbSeparator />
                <BreadcrumbItem>{currentMainMenuItemData.productCategories?.[currentCategory || 0]?.productCategoryMenuTitle}</BreadcrumbItem>
              </div>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </TransitionContainer>
    </div>
  )
}

export default MobileMenuBreadcrumbs
