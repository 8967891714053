import { FunctionComponent } from 'react'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'

import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Button } from '@ui/components/ui/button'

import { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MenuCard from '../MenuCard'
import StylesMenu from './StylesMenu'

const CategoryMenu: FunctionComponent = () => {
  const { locale } = useRouter()
  const { handleMobileMenuClose, handleMenuChange, data, currentMainMenuItem, setCurrentCategory } = useMobileMenu()
  const menuItem = data?.mainMenuItems?.[currentMainMenuItem || 0] as MainMenuItem
  const { productCategories, mainMenuCards } = menuItem || {}

  return (
    <div className='flex h-full flex-col gap-10 px-4 pt-10'>
      <div className='flex h-full flex-col gap-10 px-0'>
        <ul className='flex flex-col'>
          {productCategories?.map((productCategory, i) => (
            <li key={i}>
              <Button
                variant='ghost'
                className='flex items-center gap-1 px-2 py-3 text-btn-xl'
                data-testid='mobile-menu-category-link'
                onClick={() => {
                  trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                    clickDestination: makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory.productCategory.slug, _type: 'collection' } }, locale),
                    navigationLevel: 2,
                    variant: NAVIGATION_LINK_VARIANTS.TRIGGER,
                  })
                  handleMenuChange({ type: 'styles', component: <StylesMenu /> }, 'forward')
                  setCurrentCategory(i)
                }}
              >
                <div className='flex items-center gap-2'>{productCategory.productCategoryMenuTitle}</div>
                <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
              </Button>
            </li>
          ))}
        </ul>
      </div>

      <div className='mt-auto flex gap-2 pb-8'>
        {mainMenuCards?.map(({ image, pageLink }, i) => (
          <MenuCard
            key={i}
            image={image}
            link={pageLink}
            text={pageLink.linkText}
            onClick={() => {
              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                clickDestination: makeHref(pageLink, locale),
                navigationLevel: 2,
                variant: NAVIGATION_LINK_VARIANTS.CARD,
              })
              handleMobileMenuClose()
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default CategoryMenu
