import { FormEvent, FunctionComponent, useContext, useEffect } from 'react'
import { LoaderCircle } from 'lucide-react'
import { useToggle } from 'react-use'

import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'

import { AccountContext, useAccountContext } from 'src/contexts/Account.context'
import useUpdateCustomerPassword from 'src/hooks/customer/useUpdateCustomerPassword'
import MisterInput from '../../../../shared/input/MisterInput'

interface PasswordFormProps {
  inputFields: { [key: string]: any }
  updateFields: (e: any) => void
}

const PasswordForm: FunctionComponent<PasswordFormProps> = ({ inputFields, updateFields }) => {
  const { customer } = useContext(AccountContext)
  const {
    accountData: { currentPassword, changePassword, confirmPassword, newPassword, passwordsMatchError, currentPasswordFailMessage, passwordChangeSuccess },
  } = useAccountContext()
  const {
    updateCustomerPassword,
    state: { response, loading, error },
  } = useUpdateCustomerPassword(passwordsMatchError || 'Passwords do not match.')

  const [passwordReset, setPasswordReset] = useToggle(false)

  const resetForm = () => {
    updateFields({ currentPassword: '', newPassword: '', confirmPassword: '' })
  }

  useEffect(() => {
    if (response && !loading && !error) {
      resetForm()
      setPasswordReset(true)
      setTimeout(() => {
        setPasswordReset(false)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, response])

  if (!customer?.isLoggedIn) {
    return null
  }

  const { email } = customer || {}

  const handleUpdatePassword = async (e: FormEvent) => {
    e.preventDefault()

    await updateCustomerPassword(
      {
        email,
        password: inputFields?.newPassword,
      },
      inputFields?.confirmPassword,
      inputFields?.currentPassword,
    )
  }

  return (
    <form onSubmit={(e) => handleUpdatePassword(e)} className='mb-8 grid w-full max-w-2xl grid-cols-2 gap-x-4 lg:w-[57%]'>
      <MisterInput
        wrapperClassName='col-span-2'
        inputClassName='!bg-brand-grey'
        type='password'
        label={currentPassword}
        name='currentPassword'
        id='currentPassword'
        placeholder={currentPassword}
        onChangeHandler={updateFields}
        value={inputFields?.currentPassword}
        autoComplete='current-password'
        required
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-1'
        inputClassName='!bg-brand-grey'
        type='password'
        label={newPassword}
        name='newPassword'
        id='newPassword'
        placeholder={newPassword}
        onChangeHandler={updateFields}
        value={inputFields.newPassword}
        autoComplete='new-password'
        minLength={5}
        required
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-1'
        inputClassName='!bg-brand-grey'
        type='password'
        label={confirmPassword}
        name='confirmPassword'
        id='confirmPassword'
        placeholder={confirmPassword}
        onChangeHandler={updateFields}
        value={inputFields.confirmPassword}
        autoComplete='new-password'
        minLength={5}
        required
        isValid={inputFields?.newPassword === inputFields?.confirmPassword}
      />
      <div>
        <div className={cn(error ? 'mt-4 block text-body-sm text-brand-warning lg:mt-0' : 'hidden')}>{error === passwordsMatchError ? error : currentPasswordFailMessage}</div>
        <div className={cn(passwordReset ? 'col-span-1 mt-4 block text-body-sm lg:mt-0' : 'hidden')}>{passwordChangeSuccess}</div>
      </div>
      <div className='col-span-2 flex justify-end'>
        <Button size='md' disabled={inputFields?.currentPassword === ''} type='submit'>
          {loading && <LoaderCircle className='size-4 animate-spin' />}
          {changePassword}
        </Button>
      </div>
    </form>
  )
}

export default PasswordForm
